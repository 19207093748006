import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';
import { SaveParentHoldPageDto, parentHoldRegisterService, IParentHoldRegisterInfo } from '@/app/apiServices/parentHoldRegister/public-api';

import { ISelectDto } from '@/app/apiServices/baseDto/selectDto';
import { hasRegister, standardScoreMessage } from '@/config/message'
import { notThisCity_stepsList } from '@/core/utils/enum/notThisCity';
import { getStepsIcon } from '@/core/utils/index'
import { filterSelect } from '@/core/utils';
import { BaseResultDto } from '@/app/apiServices/baseDto/resultDto';
import date from '@/core/utils/date';
import storage from '@/core/utils/storage';
@Component
export default class parentInformationTS extends Vue {
    formModel = new SaveParentHoldPageDto();
    formRule = {
        parentsAllLocal: [
            { required: true, message: '请选择父母是否都是上海户籍', type: "number", trigger: 'change' },
        ],
        isHoldResidencePermit: [
            { required: true, message: '请选择父母一方持证情况', type: "number", trigger: 'change' },
        ],
        liveCard: [
            { required: true, message: '请输入海外人才居住证证件编号', trigger: 'blur' },
        ],
        temporaryPermitDate: [
            // { required: true, message: '请选择有效期限开始日期', type: 'date', trigger: 'change' },
            {
                required: true,
                validator: (rule: any, value: Date, callback: any) => {
                    if (value) {
                        callback();
                    } else {
                        let message = "请选择有效期限开始日期";
                        switch (Number(this.formModel.isHoldResidencePermit)) {
                            case 1:
                            case 4:
                                message = "请选择有效期限开始日期";
                                break;
                            case 2:
                                message = "请选择登记日期";
                                break;
                            case 3:
                                message = "请选择签发日期";
                                break;
                        }
                        callback(new Error(message));
                    }
                },
                type: "date", trigger: 'change'
            },
            {
                validator: (rule: any, value: Date, callback: any) => {
                    if (this.formModel.temporaryPermitEndDate == null) {
                        callback();
                    }
                    if (this.formModel.temporaryPermitEndDate != null && new Date(value) < new Date(this.formModel.temporaryPermitEndDate)) {
                        callback();
                    } else {
                        callback(new Error("有效期限开始日期不得大于或等于结束日期"));
                    }
                },
                trigger: 'change'
            }
        ],
        temporaryPermitEndDate: [
            // { required: true, message: '请选择结束日期', type: 'date', trigger: 'change' },
            {
                required: true,
                validator: (rule: any, value: Date, callback: any) => {
                    if (value) {
                        callback();
                    } else {
                        let message = "请选择结束日期";
                        switch (Number(this.formModel.isHoldResidencePermit)) {
                            case 1:
                            case 4:
                                message = "请选择结束日期";
                                break;
                            case 2:
                                message = "请选择截止日期";
                                break;
                            case 3:
                                message = "请选择有限期限";
                                break;
                        }
                        callback(new Error(message));
                    }
                },
                type: "date", trigger: 'change'
            },
            {
                validator: (rule: any, value: Date, callback: any) => {
                    if (this.formModel.temporaryPermitDate == null) {
                        callback();
                    }
                    if (this.formModel.temporaryPermitDate != null && new Date(value) > new Date(this.formModel.temporaryPermitDate)) {
                        callback();
                    } else {
                        callback(new Error("结束日期不得小于或等于有效期限开始日期"));
                    }
                },
                trigger: 'change'
            }
        ],
        childRelation: [
            { required: true, message: '请选择持证人与幼儿关系', trigger: 'change' },
        ],
        holderName: [
            { required: true, message: '请输入持证人姓名', trigger: 'blur' },
        ],
        holderCard: [
            { required: true, message: '请输入持证人证件号', trigger: 'blur' },
        ],
        temporaryPermitAdr: [
            { required: true, message: '请输入证件显示的居住地址', trigger: 'blur' },
        ],
        isApplyScore: [
            { required: true, message: '请选择持上海市居住证的父母一方是否申请积分', type: "number", trigger: 'change' }
        ],
        isStandardScore: [
            { required: true, message: '请选择积分达标情况', type: "number", trigger: 'change' },
        ],
    };
    holdCardSelect: ISelectDto[] = [];
    parentRelationSelect: ISelectDto[] = [];

    stepsList = notThisCity_stepsList;
    activeSteps = 0;

    //获取证件开始时间lable
    get getTemporaryPermitDateLable() {
        let lable = "有效期限开始日期";
        switch (Number(this.formModel.isHoldResidencePermit)) {
            case 1:
            case 4:
                lable = "有效期限开始日期";
                break;
            case 2:
                lable = "登记日期";
                break;
            case 3:
                lable = "签发日期";
                break;
        }
        return lable;
    }

    //获取证件截止时间lable
    get getTemporaryPermitEndDateLable() {
        let lable = "结束日期";
        switch (Number(this.formModel.isHoldResidencePermit)) {
            case 1:
            case 4:
                lable = "结束日期";
                break;
            case 2:
                lable = "截止日期";
                break;
            case 3:
                lable = "有限期限";
                break;
        }
        return lable;
    }

    //获取证件居住地址lable
    get getTemporaryPermitAdrLable() {
        let lable = "证件显示的居住地址";
        switch (Number(this.formModel.isHoldResidencePermit)) {
            case 1:
            case 2:
            case 3:
                lable = "证件显示的居住地址";
                break;
            case 4:
                lable = "证明上的居住地址";
                break;
        }
        return lable;
    }

    getStepsIcon(index: number) {
        return getStepsIcon(index, this.activeSteps);
    }

    //父母一方持证情况
    isHoldResidencePermitChange(val: number) {
        //无证件
        if (val !== 0) {
            const { formModel }: any = this.$refs;
            this.$nextTick(() => {
                formModel.validateField("temporaryPermitDate");
                formModel.validateField("temporaryPermitEndDate");
            })
        }
        else {
            this.$dialog.alert({
                title: "提示",
                message: "对不起，您不属于此次幼儿园招生报名范围，无法进行报名！"
            })
                .then(() => {
                    this.$router.push("/record");
                });
        }
    }

    //是否达到分值选项Change事件
    isStandardScoreChange(val: number) {
        //父母持证为居住证类型，申请过积分，且选择“达到标准分值”
        if (this.formModel.isHoldResidencePermit == 1 && val === 1) {
            this.$dialog
                .alert({
                    title: '系统提示',
                    message: standardScoreMessage
                }).then(() => {
                    // on close
                });
        }
    }

    // 根据code 获得 name
    formModelCodeToName() {
        this.formModel.isApplyScoreDec = this.formModel.isApplyScore == 1 ? "是" : "否";
        this.formModel.isStandardScoreDec = this.formModel.isStandardScore == 1 ? "达到标准分值" : "未达到标准积分值";
        this.formModel.childRelationDec = filterSelect(this.parentRelationSelect, this.formModel.childRelation)
    }

    // 初始化 formModel
    initializationFormModel() {
        const { cardTypeName, cardType, cardNumber, gradeCode }: any = storage.GET_RegistrationInfo();
        this.formModel.gB_ZJLX = cardTypeName;
        this.formModel.gB_ZJLX_CODE = cardType;  //证件类型Code
        this.formModel.idCardNo = cardNumber;
        this.formModel.gradeCode = gradeCode;
    }

    //下一步
    nextOnClick(): void {
        const { formModel }: any = this.$refs;
        formModel.validate((valid: boolean) => {
            if (valid) {

                if (!this.formModel.parentsAllLocal) {
                    //根据code获取名称
                    this.formModelCodeToName();

                    //处理8小时时差问题
                    this.formModel.temporaryPermitDate = new Date(date.formatD((<Date>this.formModel.temporaryPermitDate).toString()));
                    this.formModel.temporaryPermitEndDate = new Date(date.formatD((<Date>this.formModel.temporaryPermitEndDate).toString()));
                }

                // 确定下一步
                const nextFun = () => {
                    const input = Object.assign(new SaveParentHoldPageDto(), this.formModel);
                    parentHoldRegisterService.saveParentHoldPage(input)
                        .then((res: BaseResultDto) => {
                            if (res.verifyResult.success) {
                                this.$store.dispatch('ACTION_ParentInfomation', this.formModel);
                                this.$router.push(`/notThisCity/childInformation`);
                            }
                            else {
                                if (res.verifyResult.exceptionCode == "GOHOME") {
                                    this.$dialog.alert({
                                        message: hasRegister,
                                    }).then(() => {
                                        this.$router.push("/record");
                                    });
                                }
                                else {
                                    this.$toast({
                                        message: res.verifyResult.message,
                                        position: 'top'
                                    });
                                }
                            }
                        })
                }
                nextFun();
            }
            else {
                this.$toast({
                    message: '填写内容存在错误请仔细核对！',
                    position: 'top'
                });
            }
        });
    }

    // 加载表单数据
    viewLoad(): void {
        const { cardType, cardNumber, gradeCode }: any = storage.GET_RegistrationInfo();
        parentHoldRegisterService.getParentHoldPage({
            gradeCode: gradeCode,
            cardNumber: cardNumber,
            cardType: cardType,
        })
            .then((res: IParentHoldRegisterInfo) => {
                // 可能为 null 不存在数据
                if (res) {
                    this.formModel = Object.assign(this.formModel, res);

                    this.holdCardSelect = res.holdCardSelect;
                    this.parentRelationSelect = res.parentRelationSelect;
                }
                this.initializationFormModel();
            });
    }
    // 加载字典
    async viewLoadDicArray() {
        this.viewLoad();
    }

    mounted(): void {
        this.viewLoadDicArray();
    }
}